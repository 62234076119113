<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-sm-9 col-md-9 col-12">
                        <a
                          @click="doLogin"
                          class="btn btn-sm btn-info"
                          id="btn_status"
                        >
                          <i class="uil uil-facebook"></i> Sign in using
                          Facebook
                        </a>
                      </div>
                      <div class="col-sm-3 col-md-3 col-12 text-end" id="btn_load">
                        <a
                          @click="getData()"
                          class="btn btn btn-primary"
                        >
                          โหลดข้อมูลเพจ
                        </a>
                      </div>
                    </div>
                    <div class="row col-12">
                      <div
                        id="status"
                        ref="chart"
                        style="left: 2%; top: 35%"
                      ></div>
                    </div>
                    <br />
                    <br />
                    <!-- <fb:login-button
                      scope="public_profile,email,pages_show_list,pages_manage_metadata,pages_messaging"
                      size="large"
                      onlogin="checkLoginState();"
                      auto_logout_link="true"
                    >
                    </fb:login-button> -->
                    <!-- id="table_account" -->
                    <!-- :items="rowsData" -->
                    <b-skeleton-wrapper :loading="loading">
                      <!-- skeleton  -->
                      <template #loading>
                        <b-skeleton-table></b-skeleton-table>
                      </template>
                      <div class="table-responsive mb-0 mt-4">
                        <b-table
                          :items="rowsData"
                          :fields="fields"
                          :current-page="1"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          responsive="sm"
                          ref="selectableTable"
                        >
                          <template #cell(index)="rowsData">
                            {{ rowsData.index + 1 }}
                          </template>
                          <template v-slot:cell(subscribe)="rowsData">
                            <ul class="list-inline mb-0">
                              <li class="list-inline-item">
                                <b-button
                                  variant="danger"
                                  class="px-2 btn btn"
                                  @click="
                                    subscribe(
                                      rowsData.item.id,
                                      rowsData.item.access_token,
                                      rowsData.item.name
                                    )
                                  "
                                >
                                  Subscribe
                                </b-button>
                              </li>
                            </ul>
                          </template>
                        </b-table>
                      </div>
                    </b-skeleton-wrapper>

                    <!-- <div class="table" ip="pageList"></div> -->

                    <!-- <button @click="subscribe(pageId, pageAcessToken)">
                      Sub
                    </button> -->

                    <!-- <div >
                        <b-alert
                        v-for="(data, index) in rowsData" :key="index"
                          variant="secondary"
                          show
                          class="alert-border alert-border-secondary"
                        >
                          <i
                            class="uil uil-pen font-size-16 text-secondary me-2"
                          ></i>
                          {{ data.description }}
                        </b-alert>
                        <br />
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script
  async
  defer
  crossorigin="anonymous"
  src="https://connect.facebook.net/en_US/sdk.js"
></script>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
// import simplebar from "simplebar-vue";
import {
  required,

  // url,
  // alphaNum,
} from "vuelidate/lib/validators";
// import branchComponent from "@/components/branchComponent.vue";
export default {
  page: {
    title: appConfig.socialFb,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },

  data() {
    return {
      loading: false,
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      title: appConfig.fbconnect,
      // pageId: '1111',
      // pageAcessToken: 'dasjkfhjasdhjas',
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "id",
          label: "เพจไอดี",
          sortable: false,
        },
        {
          key: "name",
          label: "ชื่อเพจ",
          sortable: false,
        },
        {
          key: "subscribe",
          label: "สิทธิ์การเข้าถึง",
          sortable: false,
        },
      ],
      items: [
        {
          text: "บริการ",
          active: true,
        },
        {
          text: appConfig.fbconnect,
          active: true,
        },
      ],
      // partId: this.$route.params.partId,
      model: [],
      rowsData: [],
      rowsPage: [],
      fbStatus: "",
      userBranchId: "",
      nameFb: "",
    };
  },
  validations: {
    tooltipform: {
      files: {
        required,
      },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  created: function () {
    // function subscribe(params) {
    //   FB.api(
    //     `/${pageId}/subscribed_apps?access_token=${pageAcessToken}`,
    //     "POST",
    //     {
    //       subscribed_fields: "messages,message_echoes",
    //     },
    //     function (response) {
    //       if (response && !response.error) {
    //         if (response.success) {
    //           // Swal.fire(
    //           //   appConfig.swal.title.postSuccess,
    //           //   "Subscribed",
    //           //   appConfig.swal.type.success
    //           // );
    //           // alert("Subscribed");
    //         }
    //       }
    //     }
    //   );
    // }
    // console.log("created main");
    // window.fbAsyncInit = function() {
    //   FB.init({
    //     appId: "728938971419876",
    //     cookie: true, // Enable cookies to allow the server to access the session.
    //     xfbml: true, // Parse social plugins on this webpage.
    //     version: "v13.0", // Use this Graph API version for this call.
    //   });
    //   FB.getLoginStatus(function(response) {
    //     // Called after the JS SDK has been initialized.
    //     statusChangeCallback(response); // Returns the login status.
    //   });
    // };
    // (function(d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) {
    //     return;
    //   }
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "//connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, "script", "facebook-jssdk");
    // function statusChangeCallback(response) {
    //   // Called with the results from FB.getLoginStatus().
    //   // console.log("statusChangeCallback");
    //   // this.$set(this.myArr, 2, 'strawberry');
    //   // console.log(response); // The current login status of the person.
    //   // console.log(this.rowsData,'rowsData')
    //   if (response.status === "connected") {
    //     // Logged into your webpage and Facebook.
    //     getProfile(response.authResponse.accessToken);
    //   } else {
    //     // Not logged into your webpage or we are unable to tell.
    //     document.getElementById("status").innerHTML =
    //       "กรุณาเข้าสู่ระบบเฟซบุ๊ค" + "เพื่อเข้าถึงเพจ!";
    //   }
    // }
    // function checkLoginState() {
    //   // Called when a person is finished with the Login Button.
    //   FB.getLoginStatus(function(response) {
    //     // See the onlogin handler
    //     // console.log(response);
    //     statusChangeCallback(response);
    //   });
    // }
    // function getProfile(userAccessToken) {
    //   // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
    //   FB.api("/me", function(response) {
    //     document.getElementById("status").innerHTML =
    //       "ยินดีต้อนรับคุณ, " + response.name + "!";
    //   });
    //   FB.api(`/me/accounts`, (response) => {
    //     // console.log(response, "me account");
    //     if (response.data) {
    //       // arr = response.data;
    //       localStorage.setItem("responseFb", JSON.stringify(response.data));
    //       // window.location.href = '/fb-connect';
    //       // this.rowsData = response.data;
    //       // console.log(this.rowsData, 'rows Data')
    //       // response.data.forEach((page) => {
    //       //   let table = document.getElementById("pageList");
    //       //   let button = `<button @click="subscribe('${page.id}','${page.access_token}')">Subscribe</button>`;
    //       //   table.insertAdjacentHTML(
    //       //     "beforeend",
    //       //     `<tr><td>${page.id}</td><td>${page.name}</td><td>${button}</td></tr>`
    //       //   );
    //       // });
    //     }
    //   });
    // }
  },
  created() {
  },
  mounted() {
    // const fbData = JSON.parse(localStorage.getItem("responseFb"));
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    this.userBranchId = user.branchId;
    this.callFb();
    // setTimeout(() => {
    //   if (this.fbStatus === 'connected') {
    //     this.getData();
    //   }
    // }, 1000);
    // var element = document.getElementById("status");
    // var getElement = element.getAttribute("data-status");
    // if (getElement != null) {
    //   this.getData();
    // }
    // this.rowsData = fbData;
    // console.log(this.rowsData);
    // const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    // const arrayBranch = [];
    // user.userAccessBranch.forEach((item) => {
    //   arrayBranch.push(item.branchId);
    // });
    // this.localDataBranchIdUser = arrayBranch;
    // this.branchId = user.branchId;
    // this.localData = user.userAccessBranch;
    // this.getData();
    // Set the initial number of items
  },
  // created() {
  // this.getLocalData();
  // },
  methods: {
    // getString() {
    //   var element = document.getElementById("status");
    //   var info = element.getAttribute("data-info");
    //   console.log(JSON.parse(info))
    // },
    getData() {
      var element = document.getElementById("status");
      // var getElement = element.getAttribute("data-status");
      var userId = element.getAttribute("data-fbid");
      var accessToken = element.getAttribute("data-access");
      // if (userId && accessToken) {
      this.getPage(userId, accessToken);
      // console.log(getElement);
      // }
    },
    getPage(userId, accessToken) {
      this.loading = true;
      useNetw
        .get(`https://graph.facebook.com/${userId}/accounts`, {
          params: {
            access_token: accessToken,
          },
        })
        .then((response) => {
          this.rowsData = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.error.message),
            appConfig.swal.type.error
          );
          // this.lastDate = this.toDate
        })
        .then(() => {
          this.loading = false;
        });
    },
    callFb: function () {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      window.fbAsyncInit = function () {
        FB.init({
          appId: "728938971419876",
          cookie: true, // Enable cookies to allow the server to access the session.
          xfbml: true, // Parse social plugins on this webpage.
          version: "v13.0", // Use this Graph API version for this call.
        });

        FB.getLoginStatus(function (response) {
          // Called after the JS SDK has been initialized.
          // this.statusChangeCallback(response); // Returns the login status.
          // function subscribe(pageId, pageAcessToken) {
          //   FB.api(
          //     `/${pageId}/subscribed_apps?access_token=${pageAcessToken}`,
          //     "POST",
          //     {
          //       subscribed_fields: "messages,message_echoes",
          //     },
          //     function (response) {
          //       if (response && !response.error) {
          //         if (response.success) {
          //           Swal.fire(
          //             appConfig.swal.title.postSuccess,
          //             "Subscribed",
          //             appConfig.swal.type.success
          //           );
          //           // alert("Subscribed");
          //         }
          //       }
          //     }
          //   );
          // }

          if (response.status === "connected") {
            var element = document.querySelector("#status");
            element.setAttribute("data-status", `${response.status}`);
            element.setAttribute(
              "data-fbid",
              `${response.authResponse.userID}`
            );
            element.setAttribute(
              "data-access",
              `${response.authResponse.accessToken}`
            );
            document.getElementById("btn_status").style.display = "none";
            // console.log(response, "response"); // The current login status of the person.
            // Logged into your webpage and Facebook.
            // this.getProfile(response.authResponse.accessToken);

            FB.api("/me", function (response) {
              document.getElementById("status").innerHTML =
                "ยินดีต้อนรับคุณ, " + response.name + "!";
            });

            // FB.api(`/me/accounts`, (response) => {
            //   console.log(response, "me account");
            //   if (response.data) {
            //     response.data.forEach((page, index) => {
            //       let table = document.getElementById("table_account");
            //       let button = `<button class="btn btn-danger" @click="foo('${page.id}','${page.access_token}')">Subscribe</button>`;
            //       table.insertAdjacentHTML(
            //         "beforeend",
            //         `<tr><td>${index + 1}</td><td>${page.id}</td><td>${
            //           page.name
            //         }</td><td>${button}</td></tr>`
            //       );
            //     });
            //     // localStorage.setItem(
            //     //   "responseFb",
            //     //   JSON.stringify(response.data)
            //     // );
            //   }
            // });
          } else {
            // Not logged into your webpage or we are unable to tell.
            document.getElementById("btn_load").style.display = "none";

            // localStorage.removeItem("responseFb");
            document.getElementById("status").innerHTML =
              "กรุณาเข้าสู่ระบบเฟซบุ๊ค" + "เพื่อเข้าถึงเพจ!";
            // var elem = document.getElementById('status');
            var element = document.querySelector("#status");
            element.setAttribute("data-status", `${response.status}`);

            // const arr = [
            //   {
            //     access_token:
            //       "EAAKW91R0VOQBAC0x7JbkyRMTLNWPn4tKOxWi7ZCybSGSrKwJH0YZBPpCAsGwHk1nMkn60GiFKxfQ5XvkApwzQRBofTJ3a7edZBg9iGmwWimELHBOqjnxbRN60DsV6xPkZANyehnLNYYECpwqHK63iBcZCbZCpLHpaXWlMtmBe8rKFdfX0ffj8dE4Ewl7a9ZCNvIsPtkZBzjmCQZDZD",
            //     category: "เกมเมอร์",
            //     category_list: [{ id: "471120789926333", name: "เกมเมอร์" }],
            //     id: "107320020619190",
            //     name: "Hosicolive",
            //     tasks: [
            //       "ANALYZE",
            //       "ADVERTISE",
            //       "MESSAGING",
            //       "MODERATE",
            //       "CREATE_CONTENT",
            //       "MANAGE",
            //     ],
            //   },
            //   {
            //     access_token:
            //       "EAAKW91R0VOQBAC0x7JbkyRMTLNWPn4tKOxWi7ZCybSGSrKwJH0YZBPpCAsGwHk1nMkn60GiFKxfQ5XvkApwzQRBofTJ3a7edZBg9iGmwWimELHBOqjnxbRN60DsV6xPkZANyehnLNYYECpwqHK63iBcZCbZCpLHpaXWlMtmBe8rKFdfX0ffj8dE4Ewl7a9ZCNvIsPtkZBzjmCQZDZD",
            //     category: "เกมเมอร์",
            //     category_list: [{ id: "471120789926333", name: "เกมเมอร์" }],
            //     id: "107320020619190",
            //     name: "Hosicolive",
            //     tasks: [
            //       "ANALYZE",
            //       "ADVERTISE",
            //       "MESSAGING",
            //       "MODERATE",
            //       "CREATE_CONTENT",
            //       "MANAGE",
            //     ],
            //   },
            // ];

            // element.setAttribute("data-info", `${JSON.stringify(arr)}`);

            // arr.forEach((page, index) => {
            //   let table = document.getElementById("table_account");
            //   let button = `<button class="btn btn-danger" onclick="doFoo('${page.id}','${page.access_token}')">Subscribe</button>`;
            //   table.insertAdjacentHTML(
            //     "beforeend",
            //     `<tr><td>${index + 1}</td><td>${page.id}</td><td>${
            //       page.name
            //     }</td><td>${button}</td></tr>`
            //   );
            // });
            // var getElement = element.getAttribute("data-status");
            // if (getElement == "connected") {
            //   document.getElementById("btn_status").style.display = "none";
            // }
          }
        });
        // this.fbStatus = res;
      };
      this.chkStatus();
    },
    // statusChangeCallback: function (response) {
    //   if (response.status === "connected") {
    //     console.log(response); // The current login status of the person.
    //     // Logged into your webpage and Facebook.
    //     this.getProfile(response.authResponse.accessToken);
    //   } else {
    //     // Not logged into your webpage or we are unable to tell.
    //     document.getElementById("status").innerHTML =
    //       "กรุณาเข้าสู่ระบบเฟซบุ๊ค" + "เพื่อเข้าถึงเพจ!";
    //   }
    // },
    // checkLoginState() {
    //   FB.getLoginStatus(function(response) {
    //     // See the onlogin handler
    //     // console.log(response);
    //     this.statusChangeCallback(response);
    //   });
    // },
    chkStatus: function () {
      // var element = document.getElementById("status");
      // this.fbStatus = "connected";
      setTimeout(() => {
        var element = document.getElementById("status");
        var getElement = element.getAttribute("data-status");
        // arr = getElement;
        // console.log(getElement)
        this.fbStatus = getElement;
        if(getElement === 'connected') {
          this.getData();
        }
      }, 1000);
    },
    getProfile: function () {
      FB.api("/me", function (response) {
        document.getElementById("status").innerHTML =
          "ยินดีต้อนรับคุณ, " + response.name + "!";
      });

      FB.api(`/me/accounts`, (response) => {
        // console.log(response, "me account");
        if (response.data) {
          // arr = response.data;
          // localStorage.setItem("responseFb", JSON.stringify(response.data));
          // window.location.href = '/fb-connect';
          // this.rowsData = response.data;
          // console.log(this.rowsData, 'rows Data')
          // response.data.forEach((page) => {
          //   let table = document.getElementById("pageList");
          //   let button = `<button @click="subscribe('${page.id}','${page.access_token}')">Subscribe</button>`;
          //   table.insertAdjacentHTML(
          //     "beforeend",
          //     `<tr><td>${page.id}</td><td>${page.name}</td><td>${button}</td></tr>`
          //   );
          // });
        }
      });
    },
    doLogin: function () {
      var vm = this;
      FB.login(
        function (response) {
          if (response.status === "connected") {
            if (response.authResponse != null) {
              // vm.$router.push({ name: 'fb-connect' })
              var element = document.querySelector("#status");
              element.setAttribute("data-status", `${response.status}`);
              element.setAttribute(
                "data-fbid",
                `${response.authResponse.userID}`
              );
              element.setAttribute(
                "data-access",
                `${response.authResponse.accessToken}`
              );
              // document.getElementById("btn_status").style.display = 'none';
              // console.log(response, "response"); // The current login status of the person.
              // Logged into your webpage and Facebook.
              // this.getProfile(response.authResponse.accessToken);

              FB.api("/me", function (response) {
                document.getElementById("status").innerHTML =
                  "ยินดีต้อนรับคุณ, " + response.name + "!";
              });

              // FB.api(`/me/accounts`, (response) => {
              // console.log(response, "me account");
              // if (response.data) {
              // response.data.forEach((page, index) => {
              //   let table = document.getElementById("table_account");
              //   let button = `<button class="btn btn-danger" onclick="subscribe('${page.id}','${page.access_token}')">Subscribe</button>`;
              //   table.insertAdjacentHTML(
              //     "beforeend",
              //     `<tr><td>${index + 1}</td><td>${page.id}</td><td>${
              //       page.name
              //     }</td><td>${button}</td></tr>`
              //   );
              // });
              // localStorage.setItem(
              //   "responseFb",
              //   JSON.stringify(response.data)
              // );
              //   }
              // });
              Swal.fire(
                appConfig.swal.title.postSuccess,
                "เข้าสู่ระบบด้วย Facebook สำเร็จ",
                appConfig.swal.type.success
              );
              document.getElementById("btn_status").style.display = "none";
              document.getElementById("btn_load").style.display = "block";
              // window.location.href = '/fb-connect';
            }
          } else {
            // localStorage.removeItem("responseFb");
            document.getElementById("status").innerHTML =
              "กรุณาเข้าสู่ระบบเฟซบุ๊ค" + "เพื่อเข้าถึงเพจ!";
            // var elem = document.getElementById('status');
            var element = document.querySelector("#status");
            element.setAttribute("data-status", `${response.status}`);
            var getElement = element.getAttribute("data-status");
            // console.log(getElement, 'getElement');
            // if (getElement == "unknown") {
            //   document.getElementById("btn_status").style.display = "none";
            // }
          }
        },
        {
          scope:
            "public_profile,email,pages_show_list,pages_manage_metadata,pages_messaging",
        }
      );
      // this.chkStatus();
    },
    subscribe: function (pageId, pageAcessToken, pageName) {
      FB.api(
        `/${pageId}/subscribed_apps?access_token=${pageAcessToken}`,
        "POST",
        {
          subscribed_fields: "messages,message_echoes",
        },
        function (response) {
          if (response && !response.error) {
            if (response.success) {
              // Swal.fire(
              //   appConfig.swal.title.postSuccess,
              //   "Subscribed",
              //   appConfig.swal.type.success
              // );
              // alert("Subscribed");
            }
          }
        }
      );
      this.sendPage(pageId, pageAcessToken, pageName);
    },

    // getSubscribe: function (pageId, pageAcessToken, pageName) {
    //   FB.api(
    //     `/${pageId}/subscribed_apps?access_token=${pageAcessToken}`,
    //     "GET",
    //     {
    //       subscribed_fields: "messages,message_echoes",
    //     },
    //     function (response) {
    //       if (response && !response.error) {
    //         if (response.success) {
    //           // Swal.fire(
    //           //   appConfig.swal.title.postSuccess,
    //           //   "Subscribed",
    //           //   appConfig.swal.type.success
    //           // );
    //           // alert("Subscribed");
    //         }
    //       }
    //     }
    //   );
    // },
    sendPage: function (pageId, pageAcessToken, pageName) {
      this.loading = true;
      useNetw
        .post("api/social-contact/store-page", {
          branchId: this.userBranchId,
          pageId: pageId,
          pageName: pageName,
          verifyToken: pageAcessToken,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>
